.slides {
    animation: glisse 10s infinite;
}

@keyframes glisse {
    0% {
        transform: translateY(0)
    }
    33% {
        transform: translateY(-150px);
    }
    66% {
        transform: translateY(-310px);
    }
    100% {
        transform: translateY(0);
    }
}

